import React from "react";
import Services from "./services";
import Clients from "./clients";
import "./homePage.css";
import pageTransition from "./pageTransition";
import Work from "./work";
import AboutUs from "./aboutUs";

function HomePage() {
  return (
    <div>
      <div id="landingPage">
        <video muted autoPlay loop playsInline className="back-video">
          <source
            src="https://res.cloudinary.com/drwizlf0y/video/upload/v1703416139/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Peek_Freans_Rio___Double_Chocolate___TVC_xguivm.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <AboutUs />
      <Services />
      <Work />
      <Clients />
    </div>
  );
}

export default pageTransition(HomePage);
