import React, { useRef, useState } from "react";

import emailjs from '@emailjs/browser';
import "./contact.css";
import pageTransition from "./pageTransition";
import { IoLocationSharp, IoCallSharp, IoMailSharp } from "react-icons/io5";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import ContactAnimation from "../lotties/contactAnimation.json"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const cards = [
  {
    icon: IoMailSharp,
    h1: "EMAIL",
    p1: "Send us an E-Mail",
    p2: "toontalesanimations@gmail.com",
    href:"mailto:toontalesanimations@gmail.com"
  },
  {
    icon: IoCallSharp,
    h1: "PHONE",
    p1: "Make us a Call",
    p2: "+92 3152959705",
    href:"tel:+92 3152959705"
  },
  {
    icon: IoLocationSharp,
    h1: "ADDRESS",
    p1: "Visit Us",
    p2: "A311, Block 7, Gulistan e Jauhar, Karachi, Pakistan",
    href:"https://maps.app.goo.gl/ZW1u5WgY5EUGXWDK9"
  },
];

function Contact() {
  const [isHover, setIsHover] = useState([false, 0]);
  const handleHover = (position, card) => {
    if (position === "over") {
      setIsHover([true, card]);
    } else if (position === "out") {
      setIsHover([false, card]);
    }
  };
  const transition = {
    duration: 0.5,
    ease: "easeInOut",
  };

//Form 



  const form = useRef();

  const [formData,setFormData]=useState({
    user_name:'',
    user_email:'',
    message:''
  })

  const formChangeHandle=(e)=>{
    setFormData((prevData)=>({
      ...prevData,[e.target.name]:e.target.value
    }))
    
    

  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fhp7ggg', 'template_xu4n91u', form.current, 'qjLNzDuwfAs7xHhZv')
    
      .then((result) => {
          console.log(result.text);
          setFormData({
            user_name:'',
            user_email:'',
            message:''
          })
          toast.success('Message Sent Succesfully')
          
      }, (error) => {
          console.log(error.text);
      });
  };



  return (
    <div id="contact">
      <h1 className="contact-heading">Get In Touch</h1>
      <div id="contact-cards">
        {cards.map((element, index) => {
          return (
            <div
              className="contact-cards"
              onMouseOver={() => {
                handleHover("over", index);
              }}
              onMouseOut={() => {
                handleHover("out", 1);
              }}
            >
              <motion.span
                animate={
                  isHover[0] === true && isHover[1] === index
                    ? { y: -90, opacity: 0 }
                    : {}
                }
                transition={transition}
              >
                <element.icon size={"70px"} />
              </motion.span>

              <motion.div
                animate={isHover[0] && isHover[1] === index ? { top: 0 } : {}}
                transition={transition}
                className="animated-container"
              >
                <motion.h1>{element.h1}</motion.h1>
                <motion.a  
                animate={isHover[0] && isHover[1] === index ? { color:'#42adb6' } : {}}
                href={element.href} target="_blank" >{element.p1}</motion.a>
                <motion.p
                  style={{ opacity: "0", fontSize: "1.2rem" ,fontWeight:'200'}}
                  animate={
                    isHover[0] && isHover[1] === index ? { opacity: 1 } : {}
                  }
                  transition={transition}
                >
                  {element.p2}
                </motion.p>
              </motion.div>
            </div>
          );
        })}
      </div>
      <div id="contact-form">

      <form ref={form} onSubmit={sendEmail}>
      <h1 className="form-heading">Contact Us</h1>
      <p className="form-subheading">Fill out the form  and we'll reach out to you shortly!</p>
          
               <input onChange={formChangeHandle} value={formData.user_name} required={true} minLength={2} type="text" name="user_name" placeholder="Your Name *" />
          
               <input onChange={formChangeHandle} value={formData.user_email} required={true} minLength={5} type="email" name="user_email" placeholder="Your Email *" />
          
               <textarea onChange={formChangeHandle} value={formData.message} required={true} minLength={1} name="message" placeholder="Your Message*" />
               
               <input className="submit-btn" type="submit" value="Send" />
    </form>
    <div id="contact-animation">

    <Lottie animationData={ContactAnimation} />
    </div>
      </div>
      
      <div id="map_embeded">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3617.927747917941!2d67.14998177537333!3d24.934531177880995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDU2JzA0LjMiTiA2N8KwMDknMDkuMiJF!5e0!3m2!1sen!2s!4v1703419697398!5m2!1sen!2s"
         
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        ></iframe>
      </div>
    <ToastContainer />
    </div>
  );
}

export default pageTransition(Contact);
