import React from "react";
import "./clients.css";
import { motion } from "framer-motion";

function Clients() {
  const clients = [
    "https://res.cloudinary.com/drwizlf0y/image/upload/v1703424430/peek-fr-logo_bsu5sg.png",
    "https://res.cloudinary.com/drwizlf0y/image/upload/v1703427409/1703427333522_1_icqj37.png",
    "https://res.cloudinary.com/drwizlf0y/image/upload/v1703424347/LU_logo_yurup6.png",
  ];

  return (
    <div id="clients-component">
      <div id="clients-paragraph">
        <motion.h1
          whileInView={{
            opacity: 1,
            transition: { duration: 0.7, ease: "linear", delay: 0.1 },
          }}
          initial={{ opacity: 0.4 }}
        >
          Our Clients
        </motion.h1>
        <motion.p
          whileInView={{
            opacity: 1,
            transition: { duration: 0.7, ease: "linear", delay: 0.2 },
          }}
          initial={{ opacity: 0.4 }}
        >
          At ToonTales, our diverse client base reaches across the globe,
          highlighting a spectrum of satisfied partners from various corners of
          the world. From multinational corporations to innovative startups, our
          work has had a lasting impact on clients across industries and
          geographies.
        </motion.p>
      </div>

      <div id="clients-logos">
        {clients.map((element, index) => {
          return (
            <motion.div
              className="clients-logos"
              key={index}
              whileInView={{ opacity: 1, transition: { duration: 1 } }}
              initial={{ opacity: 0 }}
            >
              <motion.img  src={element} alt="" loading="lazy" />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}

export default Clients;
