import React from "react";
import "./services.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Services() {
  const navigate = useNavigate();

  const initial = {
    scale: 0.7,
    opacity: 0.2,
  };

  return (
    <div id="Serivices">
      <div id="services-paragraphs">
        <motion.h1
          whileInView={{
            opacity: 1,
            transition: { duration: 0.7, ease: "linear", delay: 0.1 },
          }}
          initial={{ opacity: 0.1 }}
        >
          Our Services
        </motion.h1>
        <motion.p
          whileInView={{
            opacity: 1,
            transition: { duration: 0.7, ease: "linear", delay: 0.2 },
          }}
          initial={{ opacity: 0.2 }}
        >
          "At ToonTales, we specialize in creating captivating visual
          experiences through high-quality post-production and animation
          services. Our offerings encompass 2D/3D animation, character
          animation, animatics, color grading, motion graphics, infographics,
          stop motion, editing, visual effects, and more."
        </motion.p>
      </div>
      <div id="services-icons">
        <div
          className="service-details"
          onClick={() => {
            navigate("/commercial-portfolio");
          }}
        >
          <motion.img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1702813506/ToonTales%20Assets/icons/commercial_jw9xbb.png"
            alt="Loading"
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5, ease: "linear" },
            }}
            initial={initial}
          />

          <p>Commercials</p>
        </div>

        <div
          className="service-details"
          onClick={() => {
            navigate("/2d-animation-portfolio");
          }}
        >
          <motion.img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1702813506/ToonTales%20Assets/icons/2danimation_hhcsw2.png"
            alt="Loading"
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5, ease: "linear" },
            }}
            initial={initial}
          />
          <p>2D Animation</p>
        </div>
        <div
          className="service-details"
          onClick={() => {
            navigate("/3d-animation-portfolio");
          }}
        >
          <motion.img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1702813506/ToonTales%20Assets/icons/3danimation_wtryhv.png"
            alt="Loading"
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5, ease: "linear" },
            }}
            initial={initial}
          />
          <p>3D Animation</p>
        </div>
        <div
          className="service-details"
          onClick={() => {
            navigate("/character-animation-portfolio");
          }}
        >
          <motion.img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1702813506/ToonTales%20Assets/icons/characteranimation_yhlbpi.png"
            alt="Loading"
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5, ease: "linear" },
            }}
            initial={initial}
          />
          <p>Character Animation</p>
        </div>
        <div
          className="service-details"
          onClick={() => {
            navigate("/vfx-portfolio");
          }}
        >
          <motion.img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1702813507/ToonTales%20Assets/icons/vfx_wrw6el.png"
            alt="Loading"
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5, ease: "linear" },
            }}
            initial={initial}
          />
          <p>VFX</p>
        </div>
        <div className="service-details"      
        onClick={() => {
            navigate("/pre-production-portfolio");
          }}>
          <motion.img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1702813507/ToonTales%20Assets/icons/preproduction_z61jki.png"
            alt="Loading"
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: { duration: 0.5, ease: "linear" },
            }}
            initial={initial}
          />
          <p>Pre-Production</p>
        </div>
      </div>
    </div>
  );
}

export default Services;
