import React, { useRef } from "react";
import "./work.css";
import {motion} from "framer-motion";
const flipAnimationContent = [
  {
    heading: "",
    thumbnail: `https://res.cloudinary.com/drwizlf0y/image/upload/v1703416114/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/yP2oU49-eHU-HD_k5wikd.jpg`,
    video: `https://res.cloudinary.com/drwizlf0y/video/upload/v1703416126/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Adventure_kabhi_na_hoga_kam_x3tott.mp4`,
  },
  {
    heading: "",
    thumbnail: `https://res.cloudinary.com/drwizlf0y/image/upload/v1703416113/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/OYjFBohHs1M-HD_mhoapb.jpg`,
    video: `https://res.cloudinary.com/drwizlf0y/video/upload/v1703416139/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Peek_Freans_Rio___Double_Chocolate___TVC_xguivm.mp4`,
  },
  {
    heading: "",
    thumbnail: `https://res.cloudinary.com/drwizlf0y/image/upload/v1703416114/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/b3FarObluKk-HD_x670yp.jpg`,
    video: `https://res.cloudinary.com/drwizlf0y/video/upload/v1703416136/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Cocomo_Mujhay_Bhi_Do_xntkkp.mp4`,
  }
];

function Work() {
 
 
  const videoRef=useRef()
  videoRef.current=[]
  const addTovideoRef=(element)=>{
    if(element && !videoRef.current.includes(element)){

      videoRef.current.push(element)
    }
  }
 
  const togglePlayPause = (position,index) => {
    if (position === "over") {
      videoRef.current[index].play()    
    } 
  else if(position === "out") {
        videoRef.current[index].pause() }
  };



  return (
    <div id="work">
    <motion.h1 whileInView={{opacity:1,transition:{duration:0.7,ease:'linear'}}} initial={{opacity:0.1}}>Featured Projects</motion.h1>
    <motion.p whileInView={{opacity:1,transition:{duration:0.7,ease:'linear',delay:0.2}}} initial={{opacity:0.2}}>Step into the world of ToonTales, where storytelling comes to life through captivating videos. Immerse yourself in our portfolio, a testament to our dedication to creativity and visual excellence. From dynamic animations to compelling narratives, each project showcases our passion for bringing stories to the screen. Witness the art of storytelling in every frame.</motion.p>
      <div id="flipAnimations">
        {flipAnimationContent.map((element, index) => {
          return (
            <motion.div
              onMouseOver={() => {togglePlayPause("over",index); }}
              onMouseOut={() => {togglePlayPause("out",index);}}
              className="container"
              key={index}
              initial={{scale:0.95 ,opacity:0.5}}
              whileInView={{scale:1,opacity:1, transition:{duration:0.6,ease:'easeInOut'}}}
             
             
            >
              <div className="card">
                <div className="front">
                  <img src={element.thumbnail} alt="" loading="eager" />
                  <h2 className="front-layer">{element.heading}</h2>
                </div>

                <div className="back">
                  <video ref={addTovideoRef} muted loop playsInline autoPlay>
                    <source src={element.video} type="video/mp4" />
                  </video>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}

export default Work;
