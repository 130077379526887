import React, { useEffect, useRef, useState } from "react";
import "./header.css";
import Hamburger from "hamburger-react";
import { IoMdArrowDropdown } from "react-icons/io";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

function Header() {
  const [isOpen, setIsopen] = useState(false);
  const navlinkRef = useRef();
  const hamburgMenu = useRef();
  const dropDownRef = useRef();

  /*Scroll Eliminator NavBar Animation*/

  const [isNavbarVisible, setNavbarVisibility] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const isScrollingDown = prevScrollPos < currentScrollPos;

      setNavbarVisibility(!isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const showDropdown = (action) => {
    if (action === "show") dropDownRef.current.style.display = "flex";
    else dropDownRef.current.style.display = "";
  };

  const handleHamburgToggle = (toggle) => {
    if (toggle) {
      navlinkRef.current.style.height = "60vh";

      setTimeout(() => {
        navlinkRef.current.style.overflow = "visible";
      }, 300);
    } else {
      navlinkRef.current.style.height = "0px";
      navlinkRef.current.style.overflow = "hidden";
    }
  };

  const autoCloseNav = () => {
    if (window.visualViewport.width <= 770) {
      handleHamburgToggle(false);
      setIsopen(false);
    }
  };

  const linksAnimation = {
    translateX: 0,
    opacity: 1,
  };
  const linksInitial = {
    translateX: -20,
    opacity: 0,
  };
  const linksTransition = { delay: 1, duration: 0.7, ease: "linear" };

  return (
    <motion.div
      id="header"
      style={isNavbarVisible ? {} : { y: "-101%" }}
      initial={{ translateY: -100 }}
      animate={{ translateY: 0 }}
      transition={{ duration: 0.5, ease: "linear" }}
    >
      <motion.div id="header-logo" onClick={autoCloseNav} animate={{}}>
        <HashLink to="/#landingPage">
        
          <img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1703415184/logo2_z4w1ia.jpg"
            alt=""
          />
        </HashLink>
      </motion.div>
      <div ref={navlinkRef} id="header-navigations">
        <ul id="navigation-links">
          <motion.li
            className="nav-links"
            onMouseEnter={() => {
              showDropdown("show");
            }}
            onMouseLeave={() => {
              showDropdown("hide");
            }}
            initial={linksInitial}
            animate={linksAnimation}
            transition={linksTransition}
          >
            <Link
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              Services <IoMdArrowDropdown size={"22px"} />
            </Link>
            <ul ref={dropDownRef} className="dropdownMenu">
              <li>
                <Link to="/commercial-portfolio" onClick={autoCloseNav}>
                  Commercials
                </Link>
              </li>
              <li>
                <Link to="/3d-animation-portfolio" onClick={autoCloseNav}>
                  3D Animation
                </Link>
              </li>
              <li>
                <Link to="/2d-animation-portfolio" onClick={autoCloseNav}>
                  2D Animation
                </Link>
                </li>
                <li>
                <Link to="/vfx-portfolio" onClick={autoCloseNav}>
                  Vfx
                </Link>
                </li>
              <li>
                <Link to="/character-animation-portfolio" onClick={autoCloseNav}>
                  Character Animation
                </Link>
              </li>
                <li>
                <Link to="/pre-production-portfolio" onClick={autoCloseNav}>
                  Pre-Production
                </Link>
              </li>
            
            </ul>
          </motion.li>
          <motion.li
            className="nav-links"
            onClick={autoCloseNav}
            initial={linksInitial}
            animate={linksAnimation}
            transition={linksTransition}
          >
            <HashLink to="/#work">Work</HashLink>
          </motion.li>
          <motion.li
            className="nav-links"
            onClick={autoCloseNav}
            initial={linksInitial}
            animate={linksAnimation}
            transition={linksTransition}
          >
            <HashLink to="/#aboutUs">About Us</HashLink>
          </motion.li>
          <motion.li
            className="nav-links"
            onClick={autoCloseNav}
            initial={linksInitial}
            animate={linksAnimation}
            transition={linksTransition}
          >
            <Link to="/contact">Contact Us</Link>
          </motion.li>
        </ul>
      </div>
      <div id="hamburg_menu">
        <Hamburger
          onToggle={handleHamburgToggle}
          toggle={setIsopen}
          toggled={isOpen}
          ref={hamburgMenu}
        />
      </div>
    </motion.div>
  );
}

export default Header;
