import React from 'react';
import './aboutUs.css';
import Lottie from 'lottie-react';
import animation from "../lotties/animation.json"
import pageTransition from './pageTransition';


function AboutUs() {

  
  return (
    <div id='aboutUs'>
        <div id="about-us-content">
        <h1>Unveiling Creativity Through <span style={{color:'#42adb6'}} >Toon Tales </span>.</h1>
        <p>At Toon Tales, we are not just a video production company; we are storytellers, visual artists, and creative visionaries dedicated to bringing your ideas to life. With a passion for storytelling and a commitment to excellence, we embark on a journey to turn your concepts into captivating visual narratives. From concept development to post-production, our team of skilled professionals at Toon Tales is driven by a shared enthusiasm for crafting compelling videos that resonate with your audience. Whether you are looking to promote your brand, capture memorable moments, or communicate a powerful message, trust Toon Tales to transform your vision into a visual masterpiece that speaks volumes. Our commitment to quality and creativity sets us apart, making us your premier video production partner. Welcome to a world where stories come alive - welcome to Toon Tales.</p>

        </div>
        <div id="about-us-animation">
        <Lottie  animationData={animation} />
  
        
        </div>
    </div>
  )
}

export default pageTransition(AboutUs);