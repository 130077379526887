import React, { useState } from "react";
import "./portfolio.css";
import ReactPlayer from "react-player";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import pageTransition from "./pageTransition";
import { motion } from "framer-motion";


const d2AnimationPorfolio = {
  videos: [],
  heading: "",
  paragraph:'',
};
const pre_production = {
  videos: [],
  heading: "",
  paragraph:'',
};

const d3AnimationPorfolio = {
  videos: [
    {
      url: "https://res.cloudinary.com/drwizlf0y/video/upload/v1703416139/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Peek_Freans_Rio___Double_Chocolate___TVC_xguivm.mp4",
      thumbnail:
        "https://res.cloudinary.com/drwizlf0y/image/upload/v1703416113/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/OYjFBohHs1M-HD_mhoapb.jpg",
    },
    {
      url: "https://res.cloudinary.com/drwizlf0y/video/upload/v1703416136/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Cocomo_Mujhay_Bhi_Do_xntkkp.mp4",
      thumbnail:
        "https://res.cloudinary.com/drwizlf0y/image/upload/v1703416114/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/b3FarObluKk-HD_x670yp.jpg",
    },
    {
      url: "https://res.cloudinary.com/drwizlf0y/video/upload/v1703416126/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Adventure_kabhi_na_hoga_kam_x3tott.mp4",
      thumbnail:
        "https://res.cloudinary.com/drwizlf0y/image/upload/v1703416114/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/yP2oU49-eHU-HD_k5wikd.jpg",
    },
  ],
  heading: "3D Animation",
  paragraph:
    "Embark on a journey into a realm of visual wonders with Toon Tales Animation's expertise in 3D animation. From vibrant concepts to lifelike executions, we specialize in crafting immersive experiences that breathe vibrant, three-dimensional life into your ideas. Prepare to witness storytelling in an entirely new dimension.",
};
const characterAnimation = {
  videos: [],
  heading: "Character Animation",
  paragraph:
    "Crafting vibrant characters comes naturally to us at Toon Tales Animation. Starting from the conceptualization phase to the enchantment of animation, we meticulously inject each character with precision and fervor, guaranteeing their lively presence on the screen.",
};

const commercialPorfolio = {
  videos: [
    {
      url: "https://res.cloudinary.com/drwizlf0y/video/upload/v1703416139/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Peek_Freans_Rio___Double_Chocolate___TVC_xguivm.mp4",
      thumbnail:
        "https://res.cloudinary.com/drwizlf0y/image/upload/v1703416113/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/OYjFBohHs1M-HD_mhoapb.jpg",
    },
    {
      url: "https://res.cloudinary.com/drwizlf0y/video/upload/v1703416136/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Cocomo_Mujhay_Bhi_Do_xntkkp.mp4",
      thumbnail:
        "https://res.cloudinary.com/drwizlf0y/image/upload/v1703416114/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/b3FarObluKk-HD_x670yp.jpg",
    },
    {
      url: "https://res.cloudinary.com/drwizlf0y/video/upload/v1703416126/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/Adventure_kabhi_na_hoga_kam_x3tott.mp4",
      thumbnail:
        "https://res.cloudinary.com/drwizlf0y/image/upload/v1703416114/wetransfer_adventure-kabhi-na-hoga-kam-mp4_2023-12-23_1245/yP2oU49-eHU-HD_k5wikd.jpg",
    },
  ],
  heading: "Commercial Portfolio",
  paragraph:
    "At Toon Tales Animation, we specialize in enhancing narrative storytelling for businesses. Our capabilities range from careful editing and the creation of VFX assets to overseeing on-shoot CGI/VFX processes, showcasing the artistry of motion graphics, and bringing stories to life through animation. We comprehensively address every aspect of post-production, including seamless compositing and exacting color grading, to deliver a captivating visual narrative.",
};

const VfxPorfolio = {
  videos: [],
  heading: "VFX Portfolio",
  paragraph:
    "Embark on a journey into the realm of visual effects with Toon Tales Animation, your gateway to captivating illusions. Our mastery in VFX extends from seamless integration to breathtaking effects that enhance the visual narrative. Leveraging cutting-edge technology and creative innovation, we have the ability to transform ordinary scenes into extraordinary spectacles.",
};

function Portfolio({ category }) {
  const [visibleVideoCount, setVisibleVideoCount] = useState(3);
  const [isOpen, setIsOpen] = useState(false);

let portfolio;


  switch (category) {
    case "commercials":
      portfolio=commercialPorfolio;
      break;
    case "2dAnimation":
      portfolio=d2AnimationPorfolio;
      break;
    case "3dAnimation":
      portfolio=d3AnimationPorfolio;
      break;
    case "vfx":
      portfolio=VfxPorfolio;
      break;
    case "preProduction":
      portfolio=pre_production;
      break;
    case "characterAnimation":
      portfolio=characterAnimation;
      break;

    default:
      portfolio=d3AnimationPorfolio;
      break;
  }

  const viewmore_toggle = () => {
    if (!isOpen) setVisibleVideoCount(portfolio.videos.length);
    else setVisibleVideoCount(3);
    setIsOpen(!isOpen);
  };

  return (
    <div id="animation-portfolio">

    {portfolio.videos.length>0?<>  <div id="portfolio-description">
            <motion.h1
              whileInView={{
                opacity: 1,
                translateX: 0,
                transition: { duration: 0.5, ease: "easeInOut" },
              }}
              initial={{ translateX: 30, opacity: 0.1 }}
            >
              {portfolio.heading}
            </motion.h1>
            <motion.p
              whileInView={{
                opacity: 1,
                translateX: 0,
                transition: { duration: 0.5, ease: "easeInOut" },
              }}
              initial={{ opacity: 0.2, translateX: -50 }}
            >
              {portfolio.paragraph}
            </motion.p>
          </div>

          <div id="video-conatiner">
            {portfolio.videos.slice(0, visibleVideoCount).map((elem, index) => {
              return (
                <motion.div
                  className="video-container"
                  key={index}
                  whileInView={{
                    opacity: 1,
                    scale: 1,
                    transition: { duration: 0.4, ease: "easeInOut" },
                  }}
                  initial={{ scale: 0.9, opacity: 0.1 }}
                >
                  <ReactPlayer
                    url={elem.url}
                    light={elem.thumbnail}
                    width={"100%"}
                    height={"100%"}
                    playing
                    controls
                  />
                </motion.div>
              );
            })}
          </div>
        
          {portfolio.videos.length > 3 && (
            <div id="see-more">
              <button id="see-more-btn" onClick={viewmore_toggle}>
                {visibleVideoCount <= 3 ? <p>Show More</p> : "Show Less"}
                {visibleVideoCount <= 3 ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </button>
            </div>
          )}</>:<h1 id="coming-soon-heading">Coming Soon...</h1>}
        
        
    </div>
  );
}

export default pageTransition(Portfolio);
