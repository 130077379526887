import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";
import Portfolio from "./components/portfolio";
import HomePage from "./components/homePage";
import Work from "./components/work";
import ScrollToTop from "./components/ScrollToTop";
import { AnimatePresence } from "framer-motion";
import Contact from "./components/contact";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <ScrollToTop />
      <div id="whatsapp-btn">
        <a
          href="https://api.whatsapp.com/send?phone=923152959705"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src="https://res.cloudinary.com/drwizlf0y/image/upload/v1702813507/ToonTales%20Assets/icons/whatsapp_dgqhxq.png"
            alt=""
          />
        </a>
      </div>
      <Header></Header>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route index element={<HomePage />} />
          <Route
            exact
            path="/2d-animation-portfolio"
            element={<Portfolio category="2dAnimation" />}
          />
          <Route
            exact
            path="/3d-animation-portfolio"
            element={<Portfolio category="3dAnimation" />}
          />
          <Route
            exact
            path="/commercial-portfolio"
            element={<Portfolio category="commercials" />}
          />
          <Route
            exact
            path="/vfx-portfolio"
            element={<Portfolio category="vfx" />}
          />
          <Route
            exact
            path="/pre-production-portfolio"
            element={<Portfolio category="preProduction" />}
          />
          <Route
            exact
            path="/character-animation-portfolio"
            element={<Portfolio category="characterAnimation" />}
          />
          <Route exact path="/work" element={<Work />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>

      <Footer></Footer>
    </div>
  );
}

export default App;
